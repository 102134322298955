<template>
  <div>
    <AppTable
      store-module="rejection_reasons"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :form-fields="formFields"
      :table-header="tableHeader"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "RejectionReasons",
  components: {
    AppTable,
  },
  data() {
    return {
      tableFields: [
        { key: "edit_delete", label: i18n.t("edit_delete") || "Edit/Delete" },
        { key: "name_ar", label: i18n.t("name_ar") || "name in arabic" },
        // { key: "name", label: i18n.t("name") || "name" },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        { label: "Edit", variant: "primary", event: "edit" },
        { label: "Delete", variant: "danger", event: "delete" },
      ],
      formFields: [
        {
          component: "AppInput",
          attrs: {
            vModel: "name_ar",
            type: "text",
            required: true,
            label: i18n.t("name_ar") || "Name Arabic",
          },
        },
        // {
        //   component: "AppInput",
        //   attrs: {
        //     vModel: "name",
        //     type: "text",
        //     required: true,
        //     label: i18n.t("name") || "Name in Arabic",
        //   },
        // },
      ],
      tableHeader: {
        addNewBtn: true,
        multiKeySearch: true,
      },
    };
  },
};
</script>
